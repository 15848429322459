import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DistributionEnum } from 'libs/pulpo-models/src/lib/booking-record/enums/enum-distribution.model';

@Component({
  selector: 'booking-record-filter',
  templateUrl: './booking-record-filter.component.html',
  styleUrls: ['./booking-record-filter.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatSelectModule,
  ],
})
export class BookingRecordFilterComponent implements OnInit {
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportResa: EventEmitter<any> = new EventEmitter<any>();
  filterForm!: FormGroup;
  destinations: string[] = [];
  products: string[] = [];
  startCities: string[] = [];
  travelerNames: string[] = [];
  companies: string[] = [];
  DistributionEnum = DistributionEnum;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initFilterForm();
  }

  private initFilterForm(): void {
    this.filterForm = this.fb.group({
      destinationName: [],
      productReference: [],
      startCityName: [],
      supplierName: [],
      canal: [],
      businessId: [],
      travelerName: [],
      draft: [false],
      failed: [true],
    });
  }

  onSelectionChange() {
    this.filterEvent.emit(this.filterForm?.value);
  }

  onResetFilters(): void {
    this.filterForm?.reset();
    this.filterEvent.emit(this.filterForm?.value);
  }
}
