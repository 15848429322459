import { Dayjs } from 'dayjs';
import { AccommodationProviderEnum } from './enums/accommodation-provider.enum';
import { PulpoCacheRoom } from './pulpo-cache-room.model';
import { AccommodationPulpoBoard } from './accommodation-pulpo-board.model';
import { AccommodationPulpoBoardPrice } from './accommodation-pulpo-board-price.model';
import { NewAccommodation } from './new-accommodation.model';
import { AccommodationPulpoCache } from './accommodation-pulpo-cache.model';
import { AccommodationPulpoBooking } from './accommodation-pulpo-booking.model';
import { AccommodationWHCache } from './accommodation-wh-cache.model';
import { AccommodationWHBooking } from './accommodation-wh-booking.model';
import { WHCacheRoomCapacity } from './webhotelier/wh-cache-room-capacity.model';
import { RateChoiceEnum } from './enums/rate-choice.enum';
import { WHCacheChildrenPolicy } from './webhotelier/wh-cache-children-policy.model';
import { HotelbedsBookingRoom } from './hotelbeds/hotlebeds-booking-room.model';
import { HotelbedsBooking } from './hotelbeds/hotelbeds-booking.model';
import { AccommodationCache } from './accommodation-cache.model';

export class NewAccommodationRewired {
  id?: string;
  name: string;
  internalReference: string;
  countryId: number;
  isDeletable: boolean;
  modifiedAt: Dayjs;
  modifiedBy: string;
  provider: AccommodationProviderEnum;
  cityName: string;
  details: AccommodationDetails;
  cacheSelectionAlgorithm: SelectionAlgorithm;
}

export enum SelectionAlgorithm {
  LOWEST_PRICE = 'LOWEST_PRICE',
}

export class AccommodationDetails {
  id?: string;
}

export class AccommodationHBDetails extends AccommodationDetails {
  propertyCode: string;
  bookingRooms: HotelbedsBookingRoom[];
  minStay: number;
}

export class AccommodationPulpoDetails extends AccommodationDetails {
  destinationId: number;
  pulpoCacheRoomList: PulpoCacheRoom[];
  boardList: AccommodationPulpoBoard[];
  boardPriceDurationDTOList: AccommodationPulpoBoardPrice[];
  cityId: number;
}

export class AccommodationWHDetails extends AccommodationDetails {
  propertyCode: string;
  minStay: number;
  webHotelierBookingRoomDTOList: {
    id?: string;
    propertyCode: string;
    propertyName: string;
    roomCode: string;
    roomCategory: string;
    originalPrice?: number;
    priceWithProductMargin?: number;
    priceWithContractMargin?: number;
    star: number;
    description: string;
    imageUrl: string;
    capacity: WHCacheRoomCapacity;
    summaryId?: string;
    contractId?: number;
    adults: number;
    children: number;
    infants: number;
    rateChoice: RateChoiceEnum;
    staySettingsDTO: {
      nightsMin: number;
      nightsMax: number;
      roomsMax: number;
    };
    childrenPolicy: WHCacheChildrenPolicy;
    rateInfos: {
      rateId: string;
      boardName: string;
    }[];
    commbinationDTOS: { adults: number; children: number; infants: number }[];
  }[];
}

// NewAccomRewired --> NewAccom
export function unwireAccommodationRewired(
  accommodationRewired: NewAccommodationRewired | null
): NewAccommodation | null {
  if (!accommodationRewired) {
    return null;
  }
  const newAccommodation = new NewAccommodation();
  newAccommodation.id = accommodationRewired.id;
  newAccommodation.name = accommodationRewired.name;
  newAccommodation.internalReference = accommodationRewired.internalReference;
  newAccommodation.countryId = accommodationRewired.countryId;
  newAccommodation.isDeletable = accommodationRewired.isDeletable;
  newAccommodation.modifiedAt = accommodationRewired.modifiedAt;
  newAccommodation.bookingProvider = accommodationRewired.provider;
  newAccommodation.modifiedBy = accommodationRewired.modifiedBy;
  newAccommodation.cityName = accommodationRewired.cityName;

  if (accommodationRewired.provider === AccommodationProviderEnum.PULPO) {
    const cache = new AccommodationPulpoCache();
    cache.id = accommodationRewired.details.id;
    cache.destinationId = (
      accommodationRewired.details as AccommodationPulpoDetails
    ).destinationId;
    cache.pulpoCacheRoomList = (
      accommodationRewired.details as AccommodationPulpoDetails
    ).pulpoCacheRoomList;
    cache.boardList = (
      accommodationRewired.details as AccommodationPulpoDetails
    ).boardList;
    cache.boardPriceDurationDTOList = (
      accommodationRewired.details as AccommodationPulpoDetails
    ).boardPriceDurationDTOList;
    cache.cityId = (
      accommodationRewired.details as AccommodationPulpoDetails
    ).cityId;
    cache.accommodationProvider = AccommodationProviderEnum.PULPO;
    newAccommodation.cache = cache;
    newAccommodation.cacheProvider = AccommodationProviderEnum.PULPO;

    const booking = new AccommodationPulpoBooking();
    booking.accommodationProvider = AccommodationProviderEnum.PULPO;
    booking.destinationId = (
      accommodationRewired.details as AccommodationPulpoDetails
    ).destinationId;
    booking.cityId = (
      accommodationRewired.details as AccommodationPulpoDetails
    ).cityId;
    booking.id = null;
    newAccommodation.booking = booking;
    newAccommodation.bookingProvider = AccommodationProviderEnum.PULPO;
  } else if (
    accommodationRewired.provider === AccommodationProviderEnum.WEBHOTELIER
  ) {
    const cache = new AccommodationWHCache();
    cache.accommodationProvider = AccommodationProviderEnum.WEBHOTELIER;
    cache.id = accommodationRewired.details.id;
    newAccommodation.cache = cache;
    newAccommodation.cacheProvider = AccommodationProviderEnum.WEBHOTELIER;

    const booking = new AccommodationWHBooking();
    booking.id = accommodationRewired.details.id;
    booking.minStay = (
      accommodationRewired.details as AccommodationWHDetails
    ).minStay;
    newAccommodation.minStay = booking.minStay;
    booking.webHotelierBookingRoomDTOList = [];
    for (const room of (accommodationRewired.details as AccommodationWHDetails)
      .webHotelierBookingRoomDTOList) {
      const r = {
        ...room,
        propertyCode: (accommodationRewired.details as AccommodationWHDetails)
          .propertyCode,
      };
      booking.webHotelierBookingRoomDTOList.push(r);
    }
    booking.accommodationProvider = AccommodationProviderEnum.WEBHOTELIER;
    newAccommodation.booking = booking;
    newAccommodation.bookingProvider = AccommodationProviderEnum.WEBHOTELIER;
  } else if (
    accommodationRewired.provider === AccommodationProviderEnum.HOTELBEDS
  ) {
    const booking: any = {
      ...accommodationRewired.details,
      accommodationProvider: AccommodationProviderEnum.HOTELBEDS,
    } as HotelbedsBooking;
    booking.bookingRooms.forEach((room: any) => {
      room.selectedRoom.pulpoName = room.pulpoName;
      room.rateChoice = RateChoiceEnum.PRICE;
    });

    booking.propertyCode = booking.property.code;
    delete booking.property;
    newAccommodation.cache = booking as AccommodationCache;
    newAccommodation.booking = booking;
    newAccommodation.cacheProvider = AccommodationProviderEnum.HOTELBEDS;
    newAccommodation.bookingProvider = AccommodationProviderEnum.HOTELBEDS;
    newAccommodation.minStay = booking.minStay;
  }

  if (newAccommodation.booking) {
    newAccommodation.booking['@type'] =
      newAccommodation.booking.accommodationProvider.toString();
  }

  if (newAccommodation.cache) {
    newAccommodation.cache['@type'] =
      newAccommodation.cache.accommodationProvider.toString();
  }
  return newAccommodation;
}

// NewAccom --> NewAccomRewired
export function rewireNewAccommodation(
  accommodation: NewAccommodation | null
): NewAccommodationRewired | null {
  if (!accommodation) {
    return null;
  }
  const newAccommodationRewired = new NewAccommodationRewired();
  newAccommodationRewired.id = accommodation.id;
  newAccommodationRewired.name = accommodation.name;
  newAccommodationRewired.internalReference = accommodation.internalReference;
  newAccommodationRewired.countryId = accommodation.countryId;
  newAccommodationRewired.isDeletable = accommodation.isDeletable;
  newAccommodationRewired.modifiedAt = accommodation.modifiedAt;
  newAccommodationRewired.provider = accommodation.bookingProvider;
  newAccommodationRewired.modifiedBy = accommodation.modifiedBy;
  newAccommodationRewired.cityName = accommodation.cityName;

  if (accommodation.bookingProvider === AccommodationProviderEnum.PULPO) {
    const details = new AccommodationPulpoDetails();
    details.id = accommodation.cache.id;
    details.destinationId = (
      accommodation.cache as AccommodationPulpoCache
    ).destinationId;
    details.pulpoCacheRoomList = (
      accommodation.cache as AccommodationPulpoCache
    ).pulpoCacheRoomList;
    details.boardList = (
      accommodation.cache as AccommodationPulpoCache
    ).boardList;
    details.boardPriceDurationDTOList = (
      accommodation.cache as AccommodationPulpoCache
    ).boardPriceDurationDTOList;
    details.cityId = (accommodation.cache as AccommodationPulpoCache).cityId;
    newAccommodationRewired.details = details;
    newAccommodationRewired.cacheSelectionAlgorithm =
      SelectionAlgorithm.LOWEST_PRICE;
    newAccommodationRewired.provider = AccommodationProviderEnum.PULPO;
  } else if (
    accommodation.bookingProvider === AccommodationProviderEnum.WEBHOTELIER
  ) {
    const details = new AccommodationWHDetails();
    details.id = accommodation.booking?.id as string;
    details.propertyCode = (
      accommodation.booking as AccommodationWHBooking
    )?.webHotelierBookingRoomDTOList?.[0]?.propertyCode;
    details.webHotelierBookingRoomDTOList = (
      accommodation.booking as AccommodationWHBooking
    )?.webHotelierBookingRoomDTOList;
    details.minStay = accommodation.minStay;
    newAccommodationRewired.details = details;
    newAccommodationRewired.cacheSelectionAlgorithm =
      SelectionAlgorithm.LOWEST_PRICE;
    newAccommodationRewired.provider = AccommodationProviderEnum.WEBHOTELIER;
  } else if (
    accommodation.bookingProvider === AccommodationProviderEnum.HOTELBEDS
  ) {
    const details = new AccommodationHBDetails();
    details.id = accommodation.booking?.id as string;
    details.propertyCode = (accommodation.booking as HotelbedsBooking)
      ?.propertyCode as string;
    details.bookingRooms = (
      accommodation.booking as HotelbedsBooking
    )?.bookingRooms;
    details.bookingRooms.forEach((room: any) => {
      room.pulpoName = room.selectedRoom.pulpoName;
      room.rateChoice = RateChoiceEnum.PRICE;
    });
    details.minStay = accommodation.minStay;
    newAccommodationRewired.details = details;
    newAccommodationRewired.provider = AccommodationProviderEnum.HOTELBEDS;
  }
  console.log('rewired', newAccommodationRewired);
  return newAccommodationRewired;
}
