import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '../../../app-config';
import { Observable } from 'rxjs';
import { HBHotel } from '@pulpo/pulpo-models';
import { CacheBucket, withCache } from '@ngneat/cashew';

@Injectable({ providedIn: 'root' })
export class HotelbedsHBService extends ApiService<any> {
  hotelbedsBucket = new CacheBucket();

  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'accom/api/hbeds');
    if (environment.name == 'localDev') {
      this.url = 'http://localhost:9090';
    }
  }

  getCountries(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.url}/${this.endpoint}/country`, {
      observe: 'response',
      context: withCache({
        bucket: this.hotelbedsBucket,
        key: 'countries',
      }),
    });
  }

  getSegments(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.url}/${this.endpoint}/segment`, {
      observe: 'response',
      context: withCache({
        bucket: this.hotelbedsBucket,
        key: 'segments',
      }),
    });
  }

  getDestinations(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.url}/${this.endpoint}/destination`, {
      observe: 'response',
      context: withCache({
        bucket: this.hotelbedsBucket,
        key: 'destinations',
      }),
    });
  }

  getDestinationsByCountry(countryCode: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.url}/${this.endpoint}/destination/filtered?countrycode=${countryCode}`,
      {
        observe: 'response',
        context: withCache({
          bucket: this.hotelbedsBucket,
          key: `destinations-${countryCode}`,
        }),
      }
    );
  }

  queryHotelPage(
    req?: any
  ): Observable<HttpResponse<{ content: any[]; totalElements: number }>> {
    const options = this.createRequestOption(req);
    return this.http.get<any>(`${this.url}/${this.endpoint}/hotel/page`, {
      params: options,
      observe: 'response',
    });
  }

  //TODO request filtering
  queryHotels(req?: any): Observable<HttpResponse<HBHotel[]>> {
    const options = this.createRequestOption(req);
    return this.http.get<any>(`${this.url}/${this.endpoint}/hotel`, {
      params: options,
      observe: 'response',
    });
  }

  getHotel(id: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.url}/${this.endpoint}/hotel/${id}`, {
      observe: 'response',
    });
  }

  getRoomCategoriesByHotel(id: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.url}/${this.endpoint}/room-category/${id}`,
      {
        observe: 'response',
      }
    );
  }
}
