<table class="table table-striped">
  <thead>
    <tr>
      <th>Nom</th>
      <th>Source</th>
      <th>Type</th>
      <th>Lieu</th>
      <th>ADT / U</th>
      <th>CHD</th>
      <th>Prix</th>
    </tr>
  </thead>
  <tbody *ngFor="let option of optionRecord.options || []; let i = index">
    <tr>
      <td>
        <span style="font-size: small" [matTooltip]="option.name ">{{ option.name | ShortTextPipe:'10' }}</span>
      </td>
      <td>{{ option.provider }}</td>
      <td>{{ option.optionType }}</td>
      <td>{{ option.location }}</td>
      <td>{{ option.nbUnits || option.nbAdults }}</td>
      <td>{{ option.nbUnits ? '' : option.nbChildren }}</td>
      <td><ng-container *ngIf="!option.mandatory"><span> {{option.totalPrice?.toFixed(2)}} €</span></ng-container>
        <ng-container *ngIf="option.mandatory"><span> inclus</span></ng-container>
      </td>
    </tr>
  </tbody>
</table>