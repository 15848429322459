import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import ApiService from '../api.service.abstract';
import { HotelbedsProperty, NewAccommodation } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NewAccommodationHotelbedsService extends ApiService<NewAccommodation> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(
      environment,
      http,
      'accommodation/api/accommodations/hotelbeds',
      routeReuse
    );
    if (environment.name == 'localDev') {
      this.url = 'http://localhost:8088';
    }
  }

  public getPropertiesByCountryAndCity(
    countryCode: string,
    city: string
  ): Observable<HttpResponse<HotelbedsProperty[]>> {
    return this.http.get<HotelbedsProperty[]>(
      `${this.url}/${this.endpoint}/properties?countryCode=${countryCode}&city=${city}`,
      {
        observe: 'response',
      }
    );
  }

  public getCitiesByCountry(
    countryCode: string
  ): Observable<HttpResponse<string[]>> {
    return this.http.get<string[]>(
      `${this.url}/${this.endpoint}/cities-by-country-code?countryCode=${countryCode}`,
      {
        observe: 'response',
      }
    );
  }

  public getCountries(): Observable<HttpResponse<string[]>> {
    return this.http.get<string[]>(`${this.url}/${this.endpoint}/countries`, {
      observe: 'response',
    });
  }

  public getCalendarAvailability(
    propertyCode: string,
    year: number,
    month: number,
    room: string,
    board: string
  ): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(
      `${this.url}/${this.endpoint}/calendar-availability?property-code=${propertyCode}&year=${year}&month=${month}&room=${room}&board=${board}`,
      {
        observe: 'response',
      }
    );
  }
}
