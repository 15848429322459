import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { SpecialSale } from '@pulpo/pulpo-models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpecialSaleService extends ApiService<SpecialSale> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'product/special-sales');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8091';
    }
  }

  sendMail(specialSale: SpecialSale): Observable<HttpResponse<void>> {
    return this.http.post<void>(
      `${this.url}/${this.endpoint}/send-mail/${specialSale.id}`,
      {},
      { observe: 'response' }
    );
  }

  getSalesByContractId(
    contractId: number
  ): Observable<HttpResponse<SpecialSale[]>> {
    return this.http.get<SpecialSale[]>(
      `${this.url}/${this.endpoint}/contract/${contractId}`,
      { observe: 'response' }
    );
  }

  getAllSales(): Observable<HttpResponse<SpecialSale[]>> {
    return this.http.get<SpecialSale[]>(
      `${this.url}/${this.endpoint}/list-all`,
      { observe: 'response' }
    );
  }
}
