<div class="row">
  <div class="col-sm-8">
    <div *ngFor="let itinerary of [flightRecord.offer.way, flightRecord.offer.back]">
      <div class="row background m-1 p-1 corners font-weight-bold">
        {{ 'Vols ' +
        itinerary.segments[0].origin +
        '-' +
        itinerary.segments[itinerary.segments.length - 1].destination }}
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Décollage</th>
            <th>Atterrissage</th>
            <th>Vol</th>
            <th>Bagages</th>
            <th>ADT</th>
            <th>CHD</th>
            <th>BEBE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let segment of itinerary.segments">
            <td>
              {{ segment.departureTime | date : 'dd/MM/YYYY' }}
              {{ segment.origin }}
              {{ segment.departureTime | date : 'HH:mm' }}
            </td>
            <td>
              {{ segment.arrivalTime | date : 'dd/MM/YYYY' }}
              {{ segment.destination }}
              {{ segment.arrivalTime | date : 'HH:mm' }}
            </td>
            <td>
              {{ segment.airlineIata + ' ' + segment.flightNumber }}
            </td>
            <td>
              <span [class.colored]="flightRecord.offer.isLuggageIncluded"
                [class.red-colored]="!flightRecord.offer.isLuggageIncluded">
                {{ luggageSummary }}
              </span>
            </td>
            <td>{{ travelerRecord.adultNumber }}</td>
            <td>{{ travelerRecord.childNumber }}</td>
            <td>{{ travelerRecord.babyNumber }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <div class="row background m-1 p-1 corners font-weight-bold">
        Informations bagages
      </div>
      <table class="table table-striped" *ngIf="(flightRecord.bags?.length || 0) > 0">
        <thead>
          <tr>
            <th>Quantité</th>
            <th>Poids (KG)</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let bag of flightRecord.bags">
            <td>{{ bag.quantity || '1' }}</td>
            <td>{{ bag.weight ? bag.weight : '23' }} kg</td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="(flightRecord.bags?.length || 0) === 0 && !flightRecord.offer.isLuggageIncluded">
        <p class="text-start">Pas de bagages enregistrés</p>
      </div>
      <div *ngIf="(flightRecord.bags?.length || 0) === 0 && flightRecord.offer.isLuggageIncluded">
        <p class="text-start">Bagages inclus</p>
      </div>
    </div>
  </div>
  <div class="col-sm-4">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Source</th>
          <th>Réf. Pulpo</th>
          <th>PNR Compagnie</th>
          <th>PNR Fournisseur</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{ flightRecord.source || 'N/A'
            }}
          </td>
          <td>{{ flightRecord.flightInternalReference }}</td>
          <td>{{ flightRecord.locatorCode }}</td>
          <td>{{ flightRecord.supplierLocatorCode }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>