import { FlightSettings } from './flight-settings.model';
import { FlightRouting } from './flight-routing.model';
import { FlightProviderEnum } from './enums/enum-flight-provider.model';
import { FlightArrivalDayPolicyEnum } from './enums/enum-flight-arrival-day-policy.model';
import { FlightStopover } from './flight-stopover.model';

export class FlightReference {
  id?: string;
  name: string;
  reference: string;
  companyId: number;

  countryId: number;
  destinationId: number;

  bookingSources: FlightProviderEnum[];
  cacheSources: FlightProviderEnum[];

  routings: FlightRouting[];

  settings: FlightSettings;
  priceRules: {
    defaultAirportTaxes: number;
  };
  timeRules: {
    flightArrivalTimeBoundary: string;
    flightArrivalPolicy: FlightArrivalDayPolicyEnum;
    flightArrivalDayPolicy: FlightArrivalDayPolicyEnum;
  };

  durations: number[];
  stopovers: FlightStopover[];
  deletable: boolean;
  lastUpdateDate: string;
}
